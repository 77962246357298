class MiniCart extends HTMLElement {
  constructor() {
    super();
    this.sectionId = this.dataset.section;
    this.maxSkuCount = Number(this.dataset.maxskuCount);
    this.maxSkuError = this.dataset.skuError;

    window.cartConfig = {
      maxSkuCount: this.maxSkuCount,
      maxSkuError: this.maxSkuError,
    };
    window.miniCartId = this.sectionId;
    window.addEventListener("custom:updateCart", (e) => {
      let parser = new DOMParser();
      let miniCartHtml = parser
        .parseFromString(e.detail.sections[window.miniCartId], "text/html")
        .querySelector("mini-cart").innerHTML;
      this.updateCart.call(this, miniCartHtml);
    });

    window.addEventListener("custom:displayCart", this.displayCart.bind(this));

    this.addEventListener("custom:cartLoading", () => {
      this.querySelector('[data-role="cart-items"]').classList.add("loading");
    });
    this.addEventListener("custom:cartLoadingFinished", () => {
      this.querySelector('[data-role="cart-items"]').classList.remove(
        "loading"
      );
    });

    document.addEventListener("click", (e) => {
      if (
        e.target.dataset.role == "mini-cart-toggle" ||
        e.target.closest('[data-role="mini-cart-toggle"]')
      ) {
        this.toggleMiniCart.call(this);
      }
    });
    if (this.dataset.hover == "true") {
      document.addEventListener("mouseover", (e) => {
        if (
          e.target.dataset.role == "mini-cart-toggle" ||
          e.target.closest('[data-role="mini-cart-toggle"]')
        ) {
          if (window.innerWidth > 1024) {
            this.displayCart.call(this);
          }
        }
      });
    }
  }
  updateCart(html) {
    this.innerHTML = html;
    document.querySelectorAll('[data-role="cart-count"]').forEach((el) => {
      el.textContent = this.querySelector(
        '[data-role="cart-count"]'
      ).textContent;
      let count = Number(el.textContent);
      //console.log(count, "here ==>")
      count > 0 ? el.classList.remove("hidden") : el.classList.add("hidden");
    });
    this.querySelector('[data-role="cart-items"]').classList.remove("loading");
    document.dispatchEvent(new CustomEvent("custom:miniCartSliderInit"));
  }
  displayCart() {
    this.classList.add("active");
  }
  toggleMiniCart() {
    this.classList.contains("active")
      ? this.classList.remove("active")
      : this.classList.add("active");
    this.classList.contains("active")
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  }
}
customElements.define("mini-cart", MiniCart);
class CartQuantityInput extends HTMLElement {
  constructor() {
    super();
    this.decBtn = this.querySelector('[data-role="decrement"]');
    this.incBtn = this.querySelector('[data-role="increment"]');
    this.input = this.querySelector('[data-role="quantity"]');
    this.key = this.dataset.key;
    this.id = this.dataset.id;

    this.decBtn.addEventListener("click", this.updateQuantity.bind(this));
    this.incBtn.addEventListener("click", this.updateQuantity.bind(this));

    this.input.addEventListener("custom:quantityUpdate", (e) => {
      //console.log("quantity updated her--");
      if (e.detail.type == "decrease") {
        this.debounce(this.updateItem.call(this), 300).bind(this);
      } else {
        this.debounce(this.increaseItem.call(this), 300).bind(this);
      }
    });
    this.addEventListener("custom:stepdown", () => {
      this.input.stepDown();
    });
  }
  updateQuantity(e) {
    let currentValue = this.input.value;
    if (
      e.target.dataset.role == "decrement" ||
      e.target.closest('[data-role="decrement"]')
    ) {
      let customEvent = new CustomEvent("custom:quantityUpdate", {
        detail: { type: "decrease" },
      });
      Number(this.input.value) > 0 ? this.input.stepDown() : "";
      this.input.dispatchEvent(customEvent);
    } else {
      if (currentValue == window.cartConfig.maxSkuCount) {
        this.displayError.call(this, window.cartConfig.maxSkuError);
      } else {
        let customEvent = new CustomEvent("custom:quantityUpdate", {
          detail: { type: "increase" },
        });
        this.input.stepUp();
        this.input.dispatchEvent(customEvent);
      }
    }
  }
  async increaseItem() {
    this.dispatchEvent(new Event("custom:cartLoading", { bubbles: true }));
    try {
      let res = await fetch(window.Shopify.routes.root + "cart/add.js", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [
            {
              id: Number(this.id),
              quantity: 1,
            },
          ],
          sections: window.miniCartId,
          sections_url: window.location.pathname,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      if (res.status == 422) {
        this.dispatchEvent(
          new Event("custom:cartLoadingFinished", { bubbles: true })
        );
        this.input.value = this.dataset.max;
        this.displayError(res.description);
      } else {
        //console.log("updating cart ...");
        let cartEvent = new CustomEvent("custom:updateCart", {
          detail: res,
        });
        window.dispatchEvent(cartEvent);
      }
    } catch (err) {
      console.log(err);
      this.dispatchEvent(
        new Event("custom:cartLoadingFinished", { bubbles: true })
      );
      this.input.value = this.dataset.max;
    }
  }
  async updateItem() {
    this.dispatchEvent(new Event("custom:cartLoading", { bubbles: true }));
    let res = await fetch(window.Shopify.routes.root + "cart/change.js", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.key,
        quantity: Number(this.input.value),
        sections: window.miniCartId,
        sections_url: window.location.pathname,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    //console.log(res, "here==>");
    if (res.status) {
      this.dispatchEvent(
        new Event("custom:cartLoadingFinished", { bubbles: true })
      );
      this.input.value = this.dataset.max;
      this.displayError(res.message);
    } else {
      //console.log("updating cart ...");
      let cartEvent = new CustomEvent("custom:updateCart", {
        detail: res,
      });
      window.dispatchEvent(cartEvent);
    }
  }
  debounce(fn, wait) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  }
  displayError(err) {
    let wrapper = this.closest('[data-role="item-actions"]');
    let errorText = wrapper.querySelector('[data-role="qn-error"]');
    errorText.innerHTML = err;
    wrapper ? wrapper.classList.add("error") : "";
    setTimeout(() => {
      wrapper.classList.remove("error");
    }, 3000);
  }
}

customElements.define("qty-input", CartQuantityInput);

class RemoveButton extends HTMLElement {
  constructor() {
    super();
    this.key = this.dataset.key;

    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.updateItem();
    });
  }
  async updateItem() {
    this.dispatchEvent(new Event("custom:cartLoading", { bubbles: true }));
    let res = await fetch(window.Shopify.routes.root + "cart/change.js", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.key,
        quantity: 0,
        sections: window.miniCartId,
        sections_url: window.location.pathname,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    if (res.status) {
      console.log(res);
    } else {
      let cartEvent = new CustomEvent("custom:updateCart", {
        detail: res,
      });
      window.dispatchEvent(cartEvent);
    }
  }
}
customElements.define("remove-button", RemoveButton);

// Product page form submission

// let form = document.querySelector('form[action="/cart/add"]');
// console.log(form, "here")
// form.addEventListener("submit", (e) => {
//     e.preventDefault();
//     let data = form.querySelector('select[name="id"]').value;
//     console.log(data);
// })
// window.addEventListener("custom:oldAddToCart", () => {
//   fetch(window.location.pathname + "?sections=" + window.miniCartId)
//     .then((res) => res.json())
//     .then((data) => {
//       let parser = new DOMParser();
//       let miniCartHtml = parser.parseFromString(
//         data[window.miniCartId],
//         "text/html"
//       );
//       document.querySelector("mini-cart").innerHTML =
//         miniCartHtml.querySelector("mini-cart").innerHTML;
//       document.querySelectorAll('[data-role="cart-count"]').forEach((el) => {
//         el.textContent = document
//           .querySelector("mini-cart")
//           .querySelector('[data-role="cart-count"]').textContent;
//         let count = Number(el.textContent);
//         count > 0 ? el.classList.remove("hidden") : el.classList.add("hidden");
//       });
//       window.dispatchEvent(new Event("custom:displayCart"));
//     });
// });
